import styled from "styled-components";
import { getColor, getSize } from "~/spocketUI/helpers/style";

export const ModalTitle = styled.h2`
  color: ${getColor("text")};
  font-size: 22px;
  font-style: normal;
  font-weight: 800;
  line-height: 160%; /* 35.2px */
  margin: 0;
  max-width: ${getSize(43.5)};
`;

export const RatingsContainer = styled.div`
  margin-top: ${getSize(2.4)};
  display: flex;
  width: 100%;
  flex-direction: row;
  gap: ${getSize(1)};
  margin-bottom: ${getSize(2.4)};
`;

export const FeedbackTextArea = styled.textarea`
  border-radius: 12px;
  border: 1px solid rgba(0, 0, 0, 0.07);
  background: ${getColor("background")};
  padding: ${getSize(2.2)};
  width: 100%;
  margin-bottom: ${getSize(2.4)};
  min-height: ${getSize(16.6)};
  resize: none;
`;

export const ShopifyTitle = styled.h2`
  color: ${getColor("text")};
  font-size: 22px;
  font-style: normal;
  font-weight: 800;
  line-height: 160%; /* 35.2px */
  margin-top: ${getSize(2.4)};
  margin-bottom: ${getSize(1.2)};
`;

export const ShopifyDescription = styled.p`
  color: ${getColor("text")};
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 185%;
`;

export const ShopifyWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
