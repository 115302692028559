export const PLANS = ["PLANS"];
export const INVOICES = ["INVOICES"];
export const PRORATION = ["PRORATION"];

export interface ISubscriptionShopify {
  plan_id?: string;
}

export interface ISubscriptionStripe {
  plan_id?: string;
  name?: string;
  payment_method_id?: string;
  setup_intent?: string;
}

export interface ICancelSubscription {
  password: string;
  confirm_password: string;
}
