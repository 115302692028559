import styled from "styled-components";
import { getColor, getSize, responsive } from "~/spocketUI/helpers/style";
import { UIFlexProps } from "~/spocketUI/types/style";

interface SelectedProps extends UIFlexProps {
  isSelected?: boolean;
}

export const RatingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: fit-content;
  height: fit-content;
  cursor: pointer;
`;

export const RatingIconWrapper = styled.div<SelectedProps>`
  width: ${getSize(5)};
  height: ${getSize(5)};
  padding: ${getSize(0.4)};
  ${responsive("laptop")} {
    width: ${getSize(10)};
    height: ${getSize(10)};
  }
  background-color: ${({ isSelected, ...props }) =>
    getColor(isSelected ? "primary" : "background")(props)};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  transition: 0.2s ease-in-out;
  filter: grayscale(${({ isSelected }) => (isSelected ? 0 : 1)});
`;

export const Subtitle = styled.span<SelectedProps>`
  color: ${({ isSelected, ...props }) =>
    getColor(isSelected ? "primary" : "textSecondary")(props)};
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  transition: 0.2s ease-in-out;
  margin-top: ${getSize(1)};
`;
