import React, { useEffect, useMemo, useState } from "react";
import * as S from "./styles";
import Modal from "~/spocketUI/components/Modal";
import { useTranslation } from "react-i18next";
import Rating from "./Rating";
import { ReviewValueType } from "./types";
import Button from "~/spocketUI/components/Button";
import handleErrors from "~/spocketUI/helpers/handleErrors";
import { rate } from "~/api/account/requests";
import { IRateRequest } from "~/api/account/types";
//@ts-ignore
import { ReactComponent as ShopifyRatingSVG } from "~/assets/svg/ratings/shopify.svg";
import { SHOPIFY_RATE_LINK } from "~/constants/shopify";
import { useAccount } from "~/hooks/useAccount";
import { useNavigate } from "react-router-dom";

type Props = {};

const RATINGS: ReviewValueType[] = [1, 2, 3, 4, 5];

const ReviewPopup = (props: Props) => {
  const { account } = useAccount();
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [showShopify, setShowShopify] = useState<boolean>(false);
  const [selectedRating, setSelectedRating] = useState<ReviewValueType | null>(
    null
  );
  const [feedback, setFeedback] = useState("");
  const showFeedbackTextArea = useMemo(() => {
    if (!selectedRating) return false;
    return selectedRating <= 4;
  }, [selectedRating]);
  const { t } = useTranslation();

  useEffect(() => {
    setIsVisible(window.location.href.includes("show_rating=true"));
  }, []);

  const onClose = () => {
    if (
      account &&
      !account?.active_subscription &&
      !account?.has_subscribed_before
    ) {
      setIsVisible(false);
      navigate("?onboarding=true");
    } else {
      setIsVisible(false);
    }
  };

  const onSubmit = async () => {
    const data = {
      rating: selectedRating,
      feedback: showFeedbackTextArea ? feedback : null,
    } as IRateRequest;

    const toastMessages = {
      error: t("review_popup.error"),
      loading: t("review_popup.loading"),
      success: t("review_popup.success"),
    };

    const { response } = await handleErrors(() => rate(data), toastMessages);
    if (response) {
      if (selectedRating && selectedRating <= 4) {
        onClose();
      } else {
        setShowShopify(true);
      }
    }
  };

  useEffect(() => {
    if (selectedRating === 5) {
      onSubmit();
    }
  }, [selectedRating]);

  const goToShopify = () => {
    setShowShopify(false);
    onClose();
    const newWindow = window.open(
      SHOPIFY_RATE_LINK,
      "_blank",
      "noopener,noreferrer"
    );
    if (newWindow) newWindow.opener = null;
  };

  return (
    <Modal isShowing={isVisible} hide={onClose} padding="38px 42px">
      {!showShopify ? (
        <>
          <S.ModalTitle>{t("review_popup.title")}</S.ModalTitle>
          <S.RatingsContainer>
            {RATINGS.map((value) => (
              <Rating
                key={value}
                value={value}
                selectedValue={selectedRating}
                onSelect={setSelectedRating}
              />
            ))}
          </S.RatingsContainer>
          {showFeedbackTextArea ? (
            <S.FeedbackTextArea
              //@ts-ignore
              placeholder={t("review_popup.feedback_placeholder")}
              onChange={(e) => setFeedback(e.target.value)}
            />
          ) : null}
          {selectedRating !== 5 ? (
            <Button
              bgColor="primary"
              isDisabled={!selectedRating}
              fontWeight={600}
              width="100%"
              color="white"
              onClick={onSubmit}>
              {t("review_popup.send_feedback")}
            </Button>
          ) : null}
        </>
      ) : (
        <S.ShopifyWrapper>
          <ShopifyRatingSVG />
          <S.ShopifyTitle>{t("review_popup.shopify_title")}</S.ShopifyTitle>
          <S.ShopifyDescription>
            {t("review_popup.shopify_description")}
          </S.ShopifyDescription>
          <Button
            bgColor="green"
            fontWeight={800}
            alignSelf="center"
            color="white"
            onClick={goToShopify}>
            {t("review_popup.shopify_rate")}
          </Button>
        </S.ShopifyWrapper>
      )}
    </Modal>
  );
};

export default ReviewPopup;
