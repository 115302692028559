import { useTranslation } from "react-i18next";
import * as S from "./Rating.style";
//@ts-ignore
import { ReactComponent as Rating1 } from "~/assets/svg/ratings/1.svg";
//@ts-ignore
import { ReactComponent as Rating2 } from "~/assets/svg/ratings/2.svg";
//@ts-ignore
import { ReactComponent as Rating3 } from "~/assets/svg/ratings/3.svg";
//@ts-ignore
import { ReactComponent as Rating4 } from "~/assets/svg/ratings/4.svg";
//@ts-ignore
import { ReactComponent as Rating5 } from "~/assets/svg/ratings/5.svg";
import { useMemo } from "react";
import { ReviewValueType } from "./types";

type Props = {
  value: ReviewValueType;
  selectedValue: ReviewValueType | null;
  onSelect: (value: ReviewValueType | null) => void;
};

const getIcon = (value: ReviewValueType) => {
  switch (value) {
    case 1:
      return <Rating1 />;
    case 2:
      return <Rating2 />;
    case 3:
      return <Rating3 />;
    case 4:
      return <Rating4 />;
    case 5:
      return <Rating5 />;
  }
};

const Rating = ({ value, selectedValue, onSelect }: Props) => {
  const { t } = useTranslation();
  const isSelected = useMemo(() => selectedValue === value, [selectedValue]);

  return (
    <S.RatingWrapper onClick={() => onSelect(isSelected ? null : value)}>
      <S.RatingIconWrapper isSelected={isSelected}>
        {getIcon(value)}
      </S.RatingIconWrapper>
      <S.Subtitle isSelected={isSelected}>
        {t("review_popup.rating_" + value)}
      </S.Subtitle>
    </S.RatingWrapper>
  );
};

export default Rating;
