import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query";
import {
	IDropshippingSettings,
	IMPORT_LIST,
	IOrdersList,
	IOrdersParams,
	ImportListParams,
	ORDERS,
	PRODUCTS,
	DROPSHIPPING_SETTINGS
} from "./types";
import {
	createSampleOrder,
	getCategories,
	getImportList,
	getOrders,
	getProducts,
	getDropshippingSettings,
	updateDropshippingSettings
} from "./requests";
import { ICategories, IProductList, IProductParams } from "~/types/dropshipping";

export const useGetProducts = (params?: IProductParams) =>
  useInfiniteQuery<IProductList>(
    [...PRODUCTS, params],
    ({ pageParam = 1 }) => getProducts({ ...params, page: pageParam }),
    {
			getNextPageParam: (lastPage) => {
				const nextPage = lastPage.page + 1;
				if (nextPage <= lastPage.total_pages) return nextPage;
			},
    }
  );

export const useGetCategories = () =>
	useQuery<Array<ICategories>>([], () => getCategories());

export const useGetOrders = (params?: IOrdersParams) =>
	useQuery<IOrdersList>([...ORDERS, params], () => getOrders(params));

export const useGetImportList = (params?: ImportListParams) => 
	useQuery<IProductList>([...IMPORT_LIST, params], () => getImportList(params));

export const useCreateSampleOrder = () => useMutation(createSampleOrder);

export const useDropshippingSettings = () => useQuery<IDropshippingSettings>(DROPSHIPPING_SETTINGS, () => getDropshippingSettings());

export const useUpdateDropshippingSettings = () => useMutation(updateDropshippingSettings);