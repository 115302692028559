import { ILimit, Limits } from "~/types/billing";
import { useAccount } from "./useAccount";

export const usePlanFeature = () => {
  const { account } = useAccount();

  const isFeatureEnabled = (limitType: Limits) => {
    const limits: ILimit | Record<any, any> =
      account?.active_subscription?.plan?.limits || {};

    if (account?.active_subscription?.paused_at) return false;

    if (limitType === Limits.PAID_PLAN) {
      return !!account?.active_subscription?.plan;
    }

    if (!limits) return false;
    // @ts-ignore
    return !!limits[limitType];
  };

  const isFeatureDisabled = (value: Limits) => !isFeatureEnabled(value);

  const isFeaturePaused = () => !!account?.active_subscription?.paused_at;

  return { isFeatureEnabled, isFeatureDisabled, isFeaturePaused };
};
