import {
  updateCategoriesByUser,
  updatePreferredRegion,
} from "~/api/dropshipping/requests";
import { useCallback, useEffect, useState } from "react";
import { triggerShowPlansModal } from "~/spocketUI/helpers/customEvents";
import { brandingFormConfig } from "./form";
import { useGetCategories } from "~/api/dropshipping/queries";
import { useTranslation } from "react-i18next";
import { useAccount } from "~/hooks/useAccount";
import { regions } from "~/constants/regions";
import { useForm } from "~/spocketUI/hooks/useForm";
import FlexContainer from "~/spocketUI/components/FlexContainer";
import handleErrors from "~/spocketUI/helpers/handleErrors";
import ProgressBar from "~/spocketUI/components/ProgressBar";
import Button from "~/spocketUI/components/Button";

import * as S from "./styles";

export const SelectCategory = () => {
  const { t } = useTranslation();
  const { account, getAccount } = useAccount();
  const { data: categories } = useGetCategories();
  const [selectedCategories, setSelectedCategories] = useState<any>(null);
  const [isShowingRegion, setIsShowingRegion] = useState(false);

  useEffect(() => {
    if (account?.categories)
      setSelectedCategories(
        account?.categories?.map((category) => category.id)
      );
  }, [account]);

  const newCategories = categories
    ? [
        { id: null, name: t("onboarding.tranding") },
        ...categories.slice(0, 6),
        { id: "all", name: t("onboarding.all") },
      ]
    : [];

  // Function to check if all categories are selected
  const areAllCategoriesSelected = () => {
    return (
      selectedCategories?.length === categories?.length &&
      selectedCategories?.every((catId: number) =>
        categories?.some((category) => category.id === catId)
      )
    );
  };

  // Function to handle 'all' selection
  const handleAllSelection = () => {
    if (areAllCategoriesSelected()) {
      setSelectedCategories([]);
    } else {
      setSelectedCategories(categories?.map((category) => category.id));
    }
  };

  // Function to handle individual category selection
  const handleCategorySelection = (id: string | number) => {
    setSelectedCategories((prev: any[]) => {
      if (prev.includes(id)) {
        return prev.filter((catId) => catId !== id);
      } else {
        // If 'null' is already selected, remove it before adding the new category
        const newCategories = prev.includes(null) ? [] : [...prev];
        return [...newCategories, id];
      }
    });
  };

  const handleCategorySelect = (id: string | number | null) => {
    if (id === null) {
      setSelectedCategories([null]);
    } else if (id === "all") {
      handleAllSelection();
    } else {
      handleCategorySelection(id);
    }
  };

  const handleContinue = async () => {
    if (selectedCategories) {
      const { errors } = await handleErrors(
        () =>
          updateCategoriesByUser({
            categories: selectedCategories.includes(null)
              ? null
              : selectedCategories,
          }),
        // @ts-ignore
        {}
      );
      if (!errors) {
        setIsShowingRegion(true);
        getAccount();
      }
    }
  };

  if (isShowingRegion)
    return <PreferredRegion backStep={() => setIsShowingRegion(false)} />;

  return (
    <FlexContainer flexDirection="column" gap="20px">
      <FlexContainer
        flexDirection="column"
        gap="0"
        width="100%"
        alignItems="flex-start">
        <S.SelectCategoryTitle>
          {t("onboarding.category_title")}
        </S.SelectCategoryTitle>
        <ProgressBar progress={50} color="primary" />
      </FlexContainer>
      <FlexContainer gap="16px" flexWrap="wrap">
        {newCategories?.map((category) => (
          <S.CardCategory
            key={category.id}
            className={
              selectedCategories?.includes(category.id) ? "active" : ""
            }
            onClick={() => handleCategorySelect(category.id)}>
            {category.name}
          </S.CardCategory>
        ))}
      </FlexContainer>
      <FlexContainer justifyContent="space-between" width="100%">
        <Button
          bgColor="transparent"
          color="textSecondary"
          padding="16px 24px"
          onClick={() => setIsShowingRegion(true)}>
          {t("onboarding.skip")}
        </Button>
        <Button
          bgColor="primary"
          color="white"
          padding="16px 24px"
          onClick={handleContinue}>
          {t("onboarding.continue")}
        </Button>
      </FlexContainer>
    </FlexContainer>
  );
};

type PropsBrand = {
  backStep: () => void;
};

export const PreferredRegion = ({ backStep }: PropsBrand) => {
  const { t } = useTranslation();
  const form = useForm(brandingFormConfig);
  const { account } = useAccount();
  const [selectedRegion, setSelectedRegion] = useState<string>();

  useEffect(() => {
    if (account && account.preferred_region) {
      setSelectedRegion(account.preferred_region);
    }
  }, [account]);

  const handleRegionSave = useCallback(async () => {
    if (selectedRegion) {
      await handleErrors(
        () => updatePreferredRegion(selectedRegion),
        // @ts-ignore
        {}
      );
    }

    triggerShowPlansModal();
  }, [form]);

  return (
    <FlexContainer flexDirection="column" gap="20px">
      <FlexContainer
        flexDirection="column"
        gap="0"
        width="100%"
        alignItems="flex-start">
        <S.SelectCategoryTitle>{t("onboarding.region")}</S.SelectCategoryTitle>
        <ProgressBar progress={100} color="primary" />
      </FlexContainer>
      <FlexContainer gap="16px" flexWrap="wrap" width="100%">
        {regions?.map((region) => (
          <S.CardCategory
            key={region.value}
            className={selectedRegion == region.value ? "active" : ""}
            onClick={() => setSelectedRegion(region.value)}>
            {t(region.labelKey)}
          </S.CardCategory>
        ))}
      </FlexContainer>
      <FlexContainer justifyContent="space-between" width="100%">
        <Button
          bgColor="transparent"
          color="textSecondary"
          padding="16px 24px"
          onClick={() => triggerShowPlansModal()}>
          {t("onboarding.skip")}
        </Button>
        <FlexContainer>
          <Button
            bgColor="transparent"
            color="text"
            padding="16px 24px"
            onClick={() => backStep()}>
            {t("onboarding.back")}
          </Button>
          <Button
            bgColor="primary"
            color="white"
            padding="16px 24px"
            onClick={handleRegionSave}>
            {t("onboarding.continue")}
          </Button>
        </FlexContainer>
      </FlexContainer>
    </FlexContainer>
  );
};
