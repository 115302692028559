import { AxiosResponse } from "axios";

export const getAPIData = (promise: Promise<AxiosResponse>) => {
  return promise.then((res) => {
    if (!res || !res.data) {
      return Promise.reject("No data");
    }
    return res.data;
  });
};
