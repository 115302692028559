import styled from "styled-components";
import { getColor, getSize, responsive } from "~/spocketUI/helpers/style";

export const CardCategory = styled.div`
  padding: ${getSize(1.8)} ${getSize(2.3)};
  border: 1px solid ${getColor("border")};
  border-radius: ${getSize(0.8)};
  color: ${getColor("text")};
  cursor: pointer;
  font-weight: 500;
  width: 100%;
  transition: border-color 0.4s;

  &:hover {
    background-color: ${getColor("background")};
  }

  &.active {
    border-color: ${getColor("primary")};
  }

  ${responsive("laptop")} {
    width: 49%;
  }
`;

export const SelectCategoryTitle = styled.h2`
  color: ${getColor("text")};
  font-size: ${getSize(1.6)};
  font-weight: 600;
`;
