import { ReactNode, ReactPortal, useEffect } from "react"
import { ReactQueryProvider } from "./ReactQuery"
import { AccountProvider } from "./Account";
import { StoreProvider } from "./Store";

type Props = {
  children: ReactNode | ReactPortal;
}

export const ContextWrapper = ({ children }: Props) => {
  useEffect(() => {
    const psKey = import.meta.env.VITE_PARTNERSTACK_KEY;
    if (!psKey) {
      console.warn('Partnerstack key not found');
      return;
    }
    const script = document.createElement('script');
    script.src = 'https://snippet.growsumo.com/growsumo.min.js';
    script.async = true;
    // @ts-ignore
    script.onload = script.onreadystatechange = function () {
      // @ts-ignore
      const state = this.readyState;
      if (!state || state === 'complete' || state === 'loaded') {
        try {
          // @ts-ignore
          window.growsumo._initialize(psKey);
          // @ts-ignore
          if (typeof window.growsumoInit === 'function') {
            // @ts-ignore
            window.growsumoInit();
          }
        } catch (e) {
          console.error(e);
        }
      }
    };
  
    document.body.appendChild(script);
  
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <AccountProvider>
      <StoreProvider>
        <ReactQueryProvider>
          {children}
        </ReactQueryProvider>
      </StoreProvider>
    </AccountProvider>
  );
};
