import { Icon } from "@fortawesome/fontawesome-svg-core";
import {
  faSearch,
  faCog,
  faLifeRing,
  faCartShopping,
  faBagShopping,
  faSquareList,
  faTags,
  faFileInvoice,
} from "@fortawesome/pro-light-svg-icons";
import { paths } from "~/router/paths";
import { INavItem } from "~/types/routing";
import {
  useGetSidebarCountLiveProducts,
  useGetSidebarCountImportedProducts,
  useGetSidebarCountSampleOrders,
  useGetSidebarCountShopifyOrders,
} from "~/api/sidebarCounts/queries";

export const navItems: Array<INavItem> = [
  {
    sectionLabel: "nav.title_dashboard",
    namePath: "nav.find_products",
    path: paths.app.home,
    icon: faSearch as Icon,
  },
  {
    namePath: "nav.branding",
    path: paths.app.brandedInvoice,
    icon: faFileInvoice as Icon,
  },
  {
    namePath: "nav.import_list",
    path: paths.app.importList,
    icon: faSquareList as Icon,
    getCountQuery: useGetSidebarCountImportedProducts,
  },
  {
    namePath: "nav.live_products",
    path: paths.app.liveProducts,
    icon: faTags as Icon,
    getCountQuery: useGetSidebarCountLiveProducts,
  },
  {
    sectionLabel: "nav.title_orders",
    namePath: "nav.orders",
    path: paths.app.orders,
    icon: faCartShopping as Icon,
    getCountQuery: useGetSidebarCountShopifyOrders,
  },
  {
    namePath: "nav.sample_orders",
    path: paths.app.sampleOrders,
    icon: faBagShopping as Icon,
    getCountQuery: useGetSidebarCountSampleOrders,
  },
  {
    namePath: "settings.title",
    path: paths.settings.plans,
    icon: faCog as Icon,
  },
  {
    namePath: "nav.help_center",
    path: "https://help.stylesi.ai/Stylesi-ai-f603cd18234a4f149dfcac00a98d79a0",
    openInNewTab: true,
    icon: faLifeRing as Icon,
    // Uncomment this to show help center as a modal
    // showHelpCenterModal: true,
  },
];
